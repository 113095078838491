<template>
  <form
      @submit.prevent="submitDispatch"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" :info="getDispatchNoteNo"  back-to="sales"  :enableLogicalBack="true">

      <!-- Current Step Buttons -->
      <div v-for="button in currentStepButtons" :key="button.label" class="inline-block">
        <AppButton
            :color="button.color"
            :label="button.label"
            class="ml-2"
            @click="button.action"
            :icon="!!button.icon"
        >
          <template v-slot:icon v-if="button.icon">
            <Icon :iconType="button.icon" class="mr-2"/>
          </template>
        </AppButton>
      </div>

    </page-header>


    <main class="pr-6 pl-6   flex-1 overflow-y-scroll">
      <div class="flex flex-col md:flex-row items-stretch justify-between mt-6">

        <!-- Main Column 3 -->
        <div class="flex-1 bg-white p-4 rounded md:rounded-none mr-6 flex flex-col justify-between">
          <div>
            <div class="flex items-center mb-2">
              <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
                Delivery To<span class="text-red-400">*</span>
              </h3>
              <hr class="flex-1 border-primary ml-4"/>
            </div>
            <div>
              <ContactAutocomplete
                  class="w-full"
                  :disabled="true"
                  v-model="delivery_to"
                  @contactSelected="handleDeliveryToSelected"
                  @contactCleared="handleDeliveryToCleared"
                  :forceSelection="true"/>
            </div>
            <div class="w-full flex">
              <ContactInfoCard :contact="delivery_to"></ContactInfoCard>
            </div>
          </div>
          <div class="flex mt-4">
            <div class="col-span-2 flex items-center px-5 mb-3 w-full">
              <!-- Label on the left -->
              <label class="uppercase font-semibold text-sm w-2/6">Requested For</label>

              <!-- Textarea on the right -->
              <input
                  v-model="sales.requested_delivery_date" :disabled="true"
                  class="bge-input bge-input-spacing rounded w-3/6"
                  type="text"
              />
            </div>

          </div>
        </div>

        <!-- Main Column 3 -->
        <div class="flex-2 bg-white p-4 rounded md:rounded-none">
          <div class="flex items-center mb-2">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Set Details
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <div class="grid grid-cols-3 gap-2">
            <!-- First Main Column (66%) -->
            <div class="col-span-2 grid grid-cols-2 gap-2">
              <!-- First Sub-Column (50%) -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Dispatched by <span class="text-red-400">*</span></label>
                <StaffAutocomplete v-model="dispatch_note.dispatched_by"/>
              </div>
              <!-- Second Sub-Column (50%) -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Dispatch date <span class="text-red-400">*</span></label>
                <Calendar
                    v-model="dispatch_note.dispatch_date"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>
              <!-- Order Notes (full width) -->
              <div class="col-span-2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Despatch Notes</label>
                <textarea
                    v-model="dispatch_note.dispatch_notes"
                    class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-3 px-4 focus:shadow w-full"
                    placeholder="Note..."
                    rows="3"
                ></textarea>
              </div>
              <!-- Internal Notes (full width) -->
              <div class="col-span-2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Internal Despatch Notes</label>
                <textarea
                    v-model="dispatch_note.internal_dispatch_notes"
                    class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-3 px-4 focus:shadow w-full"
                    placeholder="Note..."
                    rows="3"
                ></textarea>
              </div>
            </div>
            <!-- Second Main Column (33%) -->
            <div class="col-span-1 flex flex-col gap-2">
              <!-- Supplier Reference -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Courier<span class="text-red-400">*</span></label>
                <select v-model="dispatch_note.courier" class="bge-input bge-input-spacing rounded">
                  <option>AMAZON</option>
                  <option>APC</option>
                  <option>CUSTOMER</option>
                  <option>DHL</option>
                  <option>DPD</option>
                  <option>DX</option>
                  <option>ENGINEER</option>
                  <option>FEDEX</option>
                  <option>HERMES</option>
                  <option>KEYES</option>
                  <option>MENZIES</option>
                  <option>OTHER</option>
                  <option>PALLET FORCE</option>
                  <option>PARCEL FORCE</option>
                  <option>ROYAL MAIL</option>
                  <option>SUPPLIER</option>
                  <option>TNT</option>
                  <option>UNKNOWN</option>
                  <option>UPS</option>
                  <option>WREFORDS</option>
                  <option>YODEL</option>
                </select>
              </div>
              <!-- -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Consignment Number</label>
                <input
                    v-model="dispatch_note.consignment_number"
                    class="bge-input bge-input-spacing rounded"
                    type="text"
                />
              </div>
              <!-- Requested By -->
              <!--              <div class="flex flex-col px-5 mb-3">-->
              <!--                <label class="uppercase font-semibold text-sm">Requested By</label>-->
              <!--                <input-->
              <!--                    v-model="sales.requested_by"-->
              <!--                    class="bge-input bge-input-spacing rounded"-->
              <!--                    type="text"-->
              <!--                />-->
              <!--              </div>-->
              <!--              &lt;!&ndash; Prepared By &ndash;&gt;-->
              <!--              <div class="flex flex-col px-5 mb-3">-->
              <!--                <label class="uppercase font-semibold text-sm">Created By<span class="text-red-400">*</span></label>-->
              <!--                <StaffAutocomplete v-model="sales.created_by"/>-->
              <!--              </div>-->
            </div>
          </div>
        </div>

        <div class="flex-1  p-4 rounded md:rounded-none">
        </div>


      </div>

      <!-- Select Products -->
      <div class="w-full bg-white mt-6 mb-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-2">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Select Products
            </h3>
            <hr class="flex-1 border-primary ml-4"/>


          </div>
          <DispatchNoteOrderItems :initialSalesOrder="sales" :dispatch_note_id="dispatch_note_id"></DispatchNoteOrderItems>
        </div>
      </div>

      <portal to="overlay-outlet">
        <PDFViewer :showing="pdfViewerData.isShowingViewer" :pdfBlob="pdfViewerData.pdfData"
                   :documentName="pdfViewerData.documentName" @close="closeViewer"/>
      </portal>

    </main>

  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import AppOutlinedButton from '@/components/OutlinedButton.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import ManageFiles from "@/views/HR/Engineer/JobSheet/Components/ManageFiles.vue";
import ContactInfoCard from "@/components/Contacts/InfoCard.vue";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";
const Toggle = () => import("@/components/Toggle.vue");

import DispatchNoteOrderItems from "@/views/HR/Sales/Dispatch/DispatchNoteOrderItems.vue";
const PDFViewer = () => import("@/components/PDFViewer.vue");

export default {
  name: "ManagePurchase",
  mixins: [notificationMixin],
  components: {
    StaffAutocomplete,
    PageHeader,
    Panel,
    Spinner,
    ContactAutocomplete,
    AppButton,
    Icon,
    Toggle,
    ManageFiles,
    ContactInfoCard,
    AppOutlinedButton,
    DispatchNoteOrderItems,
    PDFViewer,
  },
  props: {
    sales_order_id: String,
    dispatch_note_id: String,
  },
  data() {
    return {
      isBusy: false,
      addProductPanelOpen: false,
      sales:{},
      dispatch_note: {
        order_date:null,
        requested_delivery_date:null,
        order_type:null,
        order_items: [],
        duty: 0.0,
        shipping_cost: 0.0,
        shipping_tax_code: "",
        shipping_tax_rate: 0.0,
        supplier_reference: '',
      },

      delivery_to: null,
      pdfViewerData: {
        documentName: "",
        pdfData: null,
        isShowingViewer: false,
      },
    };
  },
  async mounted() {
    this.isBusy = true;

    if (!this.sales_order_id) {
      if (this.$store.state.user && this.$store.state.user.staff_id) {
        this.setDefaultCreatedBy()
      }
      this.setDefaultDates();
      this.isBusy = false;
      return;
    }

    if (this.sales_order_id) {
      await this.loadSalesAndApply();
      return;
    }

    this.isBusy = false;

  },
  watch: {
    // Watch for changes in route parameters
    '$route.params.sales_order_id': {
      handler: 'loadSalesAndApply',
      immediate: true
    }
  },
  computed: {

    pageTitle() {
      return this.sales_order_id ? "Despatch Note" : "Create Sales Order";
    },

    getDispatchNoteNo() {
      return this.dispatch_note && this.dispatch_note.dispatch_note_no ? this.dispatch_note.dispatch_note_no : "";
    },

    currentStepButtons() {
      const stepButtons = {
        'not packed': [
          {label: 'Save Despatch Note', color: 'blue', action: this.saveDispatch, icon: 'save'},
          {label: 'Packing List', color: 'indigo', action: this.goToPackList, icon: 'list-check'},
          {label: 'Preview Despatch Note', color: 'green', action: this.printDispatchNote, icon: 'print'},
        ],
        'partial packed': [
          {label: 'Save Despatch Note', color: 'blue', action: this.saveDispatch, icon: 'save'},
          {label: 'Packing List', color: 'indigo', action: this.goToPackList, icon: 'list-check'},
          {label: 'Preview Despatch Note', color: 'green', action: this.printDispatchNote, icon: 'print'},
        ],
        'packed': [
          {label: 'Save Despatch Note', color: 'blue', action: this.saveDispatch, icon: 'save'},
          {label: 'Packing List', color: 'indigo', action: this.goToPackList, icon: 'list-check'},
          {label: 'Confirm Dispatch', color: 'green', action: this.confirmDispatch, icon: 'thumb-up'},
          {label: 'Preview Despatch Note', color: 'green', action: this.printDispatchNote, icon: 'print'},
        ],
        'dispatched': [
          {label: 'Packing List', color: 'indigo', action: this.goToPackList, icon: 'list-check'},
          {label: 'Preview Despatch Note', color: 'green', action: this.printDispatchNote, icon: 'print'},
        ],
      };

      return stepButtons[this.dispatch_note.status] || [
        {label: 'Save Despatch Note', color: 'blue', action: this.saveDispatch, icon: 'save'},
        {label: 'Packing List', color: 'indigo', action: this.goToPackList, icon: 'list-check'},
        {label: 'Preview Despatch Note', color: 'green', action: this.printDispatchNote, icon: 'print'},
      ];
    },
  },
  methods: {

    async loadSalesAndApply() {
      this.isBusy = true;
      const dispatch = await this.loadDispatchNoteById();
      this.dispatch_note = await this.formatDispatchData(dispatch);
      this.isBusy = false;
    },

    async addNewContact() {

      await this.$router.push({
        name: 'contacts',
      });
    },


    setDefaultDates() {

      if (!this.sales.order_date) {
        this.sales.order_date = this.$moment().toDate();
      }
      if (!this.sales.requested_delivery_date) {
        this.sales.requested_delivery_date = this.$moment().add(3, 'days').toDate();
      }
    },

    setDefaultCreatedBy() {
      this.sales.created_by = {
        name: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
        staff_id: this.$store.state.user.staff_id
      };
    },

     convertDispatchNoteDatesFromUnix(dispatch) {
      const convertUnixToDate = (unixTimestamp) => {
        return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
      };

      // Convert order_date
      if (dispatch.dispatch_date) {
        dispatch.dispatch_date = convertUnixToDate(dispatch.dispatch_date);
      }
      return dispatch;
    },


    async formatSalesDataAndDeliveryInfo(sales) {
      let formattedSales = JSON.parse(JSON.stringify(sales));

      const convertUnixToDate = (unixTimestamp) => {
        return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
      };

      function findDefault(items) {
        return items && items.length > 0 ? items.find(item => item.is_default) || null : null;
      }

      if(formattedSales.requested_delivery_date){
        formattedSales.requested_delivery_date = convertUnixToDate(formattedSales.requested_delivery_date);
      }

      // Handle delivery to information
      if (formattedSales.delivery_to_contact && formattedSales.delivery_to_contact.contact_id && formattedSales.delivery_to_contact.name) {
        this.delivery_to = {
          name: formattedSales.delivery_to_contact.name,
          contact_id: formattedSales.delivery_to_contact.contact_id
        };

        this.delivery_to.address = findDefault(formattedSales.delivery_to_contact.addresses);

        this.delivery_to.people = findDefault(formattedSales.delivery_to_contact.people);
      }

      return formattedSales;

    },


    async formatDispatchData(dispatch) {
      let formattedDispatch = JSON.parse(JSON.stringify(dispatch));
      // Formatting dates
      formattedDispatch = this.convertDispatchNoteDatesFromUnix(formattedDispatch);

      return formattedDispatch;
    },

    handleDeliveryToSelected: function (selectedCustomer) {

    },

    handleDeliveryToCleared(selectedContact) {

    },

    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },

    async loadDispatchNoteById() {
      try {
        // Fetch the sales order data by sales_order_id
        const salesData = await this.SalesOrderService.getSalesOrderById(this.sales_order_id);

        if (!salesData || !salesData.dispatch_notes) {
          console.error("Sales order or despatch notes not found");
          return null; // Handle the case where there is no sales data or dispatch notes
        }

        this.sales=await this.formatSalesDataAndDeliveryInfo(salesData);


        // Validate and find the dispatch note by this.dispatch_note_id
        const dispatchNote = salesData.dispatch_notes.find(note => note.dispatch_note_id === this.dispatch_note_id);

        if (!dispatchNote) {
          console.error(`Despatch note with ID ${this.dispatch_note_id} not found`);
          return null; // Handle case where despatch note is not found
        }

        // Return the found dispatch note
        return dispatchNote;

      } catch (error) {
        this.isBusy = false;
        console.error("Error loading sales or dispatch note:", error);
        return null; // Handle the error
      }
    },

    convertDispatchDatesToUnix(dispatch) {
      const convertDateToUnix = (date) => {
        if (date instanceof Date) {
          return Math.floor(date.getTime() / 1000);
        } else if (typeof date === 'string') {
          const momentDate = this.$moment(date, 'DD-MM-YYYY', true);
          if (momentDate.isValid()) {
            return momentDate.unix();
          } else {
            const isoMomentDate = this.$moment(date, this.$moment.ISO_8601);
            if (isoMomentDate.isValid()) {
              return isoMomentDate.unix();
            }
          }
        }
        console.error('Invalid or unsupported date format:', date);
        return null;
      };

      // Convert dispatch
      if (dispatch.dispatch_date) {
        dispatch.dispatch_date = convertDateToUnix(dispatch.dispatch_date);
      }

      return dispatch;
    },



    getAllDispatchData(dispatch) {

      let convertedDispatch = JSON.parse(JSON.stringify(dispatch));

      // Convert dispatch order dates
      convertedDispatch = this.convertDispatchDatesToUnix(convertedDispatch);

      // Remove pack_list property if it exists
      delete convertedDispatch.pack_lists;

      return convertedDispatch;
    },

    onRowClick(event) {

      this.Edit(event.data);
    },

    goToPackList: async function () {
      try {
        // Check if dispatch_note and pack_lists exist and if pack_lists has at least one item
        if (!this.dispatch_note || !this.dispatch_note.pack_lists || this.dispatch_note.pack_lists.length === 0) {
          console.error("Dispatch note or pack lists are missing.");
          return;
        }

        const packList = this.dispatch_note.pack_lists[0]; // Get the first pack list

        if (!packList || !packList.pack_list_id) {
          console.error("Pack list or pack_list_id is missing.");
          return;
        }

        await this.$router.push({
          name: 'pack-list',
          params: {
            sales_order_id: this.sales_order_id,
            dispatch_note_id: this.dispatch_note_id,
            pack_list_id: packList.pack_list_id
          }
        });
      } catch (error) {
        console.error("Error navigating to the pack list:", error);
      }
    },

    validateRequiredFields() {
      const requiredFields = [
        { field: this.dispatch_note.courier, name: 'Courier' },
        { field: this.dispatch_note.dispatch_date, name: 'Dispatched Date' },
        { field: this.dispatch_note.dispatched_by, name: 'Dispatched By' }
      ];

      for (const field of requiredFields) {
        if (!field.field) {
          this.notifyError(`${field.name} is required.`);
          return false;
        }
      }
      return true;
    },


    confirmDispatch: async function () {

      if (!this.validateRequiredFields()) {
        return;
      }

      this.isBusy = true;

      try {
        if (this.dispatch_note) {
          // const updatedStatus = { status: 'dispatched' };

          const formattedDispatch = this.getAllDispatchData(this.dispatch_note);

          formattedDispatch.status='dispatched';

          await this.SalesOrderService.updateDispatchNote(
              this.sales_order_id,
              this.dispatch_note_id,
              formattedDispatch
          );
          this.notifySuccess("Despatch note status updated to 'dispatched' successfully");

          await this.loadSalesAndApply();
        } else {
          this.notifyError("Couldn't update despatch note");
        }
      } catch (error) {
        // Check if error.data is defined and has a message property
        var errorMessage = "An unexpected error occurred while updating the despatch note.";
        if (error && error.data && error.data.message) {
          errorMessage = error.data.message;
        }
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },

    saveDispatch: async function () {

      this.isBusy = true;
      const formattedDispatch = this.getAllDispatchData(this.dispatch_note);
      try {
        if (this.dispatch_note) {
          await this.SalesOrderService.updateDispatchNote(
              this.sales_order_id,
              this.dispatch_note_id,
              formattedDispatch,
          );
          this.notifySuccess("Despatch note updated successfully");

          await this.loadSalesAndApply();
        } else {

          this.notifyError("Couldn't create despatch note");
        }

      } catch (error) {
        let errorMessage = this.sales_order_id
            ? "Couldn't update despatch note"
            : "Couldn't create despatch note";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    submitDispatch: async function (event) {
      event.preventDefault();
    },

    closeViewer() {
      this.pdfViewerData = {
        pdfData: null,
        documentName: "",
        isShowingViewer: false,
      };
    },

    async printDispatchNote() {
      if (!this.sales_order_id || !this.dispatch_note_id) {
        this.notifyWarning("Missing sales order or despatch note ID. Please ensure all required fields are filled.");
        return;
      }

      try {
        const response = await this.SalesOrderService.generateDispatchNoteReport(this.sales_order_id, this.dispatch_note_id, 'dispatch-note');

        this.pdfViewerData = {
          pdfData: response,
          documentName: 'Despatch Note Report',
          isShowingViewer: true,
        };
      } catch (error) {
        console.error('Error loading PDF:', error);
        this.notifyWarning("Failed to load the PDF document. Please try again.");
      }
    }


  }
}
;
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}

</style>

<style scoped>
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

</style>